<template>
    <div>
      <b-form @submit="enviarEncuestaFinal" v-if="permisos">

        <div>
          <h5>
            {{formulario.nombreEncuesta}}
          </h5>
        </div>

        <b-button @click="iniciarEncuesta" variant="success">Iniciar</b-button>
        <div v-if="estadoEncuesta=='En curso'">
          <div v-for="pregunta in formulario.preguntas" :key="pregunta.nombre" class="mb-1" >
            <br>
            <h6>{{pregunta.nombre}}</h6>
            <b-form-select v-if="pregunta.tipo=='Múltiple'" :options="pregunta.opciones" v-model="pregunta.valor"></b-form-select>
            <b-form-input v-if="pregunta.tipo=='Númerico'" v-model="pregunta.valor"></b-form-input>
            <b-form-checkbox v-if="pregunta.tipo=='Si / No'" v-model="pregunta.valor"></b-form-checkbox>


          </div>

          <b-button type="submit" variant="primary">Enviar encuesta</b-button>

        </div>

        <b-card class="mt-3" header="Form Data Result">
          <pre class="m-0">{{ formulario }}</pre>
        </b-card>

      </b-form>

    </div>

  </template>
  
  <script>
  import axios from 'axios'
    export default {
      data() {
        return {
          permisos: true,


          estadoEncuesta:"",
          //Encuesta actual
          formulario:{ 
            "idEncuesta": "", 
            "nombreEncuesta": "MI ENCUESTA 1", 
            "preguntas": [ 
            { "no": "1", "nombre": "Comunidad", "tipo": "Múltiple", "opciones": [ "Nopaltepec", "San Felipe Teotitlán", "San Miguel Atepoxco","Venta de Cruz"], valor:""},
              { "no": "2", "nombre": "Edad", "tipo": "Númerico", valor:"" }, 
              { "no": "3", "nombre": "Sexo", "tipo": "Múltiple", "opciones": [ "Hombre", "Mujer", "Otro"], valor:"" },
              { "no": "4", "nombre": "Ocupación", "tipo": "Múltiple", "opciones": [ "Profesionista", "Hogar" ], valor:"" },
              { "no": "5", "nombre": "Nivel educativo", "tipo": "Múltiple", "opciones": [ "Primaria", "Secundaria", "Nivel medio superior", "Superior", "Otro" ], valor:"" },
              { "no": "6", "nombre": "Es usted jefe o jefa de familia", "tipo": "Si / No", valor:""},
              { "no": "7", "nombre": "Cuantos dependen economicamente de usted", "tipo": "Númerico", valor:"" },
              { "no": "8", "nombre": "Número de hijos", "tipo": "Númerico", valor:"" },
              { "no": "9", "nombre": "Piensa participar en la proximas votaciones locales", "tipo": "Si / No", valor:""},
              { "no": "10", "nombre": "Partido político de su preferencia", "tipo": "Múltiple", "opciones": [ "PAN", "PRI", "PRD", "MORENA", "Movimiento Ciudadano", "Otro" ], valor:"" },
              { "no": "11", "nombre": "Acualmente recibe algun tipo de apoyo gubernamental", "tipo": "Si / No", valor:""},
              { "no": "12", "nombre": "Que tipo de apoyo", "tipo": "Múltiple", "opciones": [ "No recibe", "Economico", "Salud", "Eduacion"], valor:"" },
              { "no": "13", "nombre": "Que gobierno le da este apoyo", "tipo": "Múltiple", "opciones": [ "No recibe", "Local", "Estatal", "Federal"], valor:"" },

              { "no": "14", "nombre": "¿Sabe usted qué es el Concejo Municipal?", "tipo": "Múltiple", "opciones": [ "Sí", "No"], valor:"" },
              { "no": "15", "nombre": "¿Cuál cree usted qué es la función del Concejo Municipal?", "tipo": "Múltiple", "opciones": [ 
                "Hacer control político a la administración municipal", 
                "Servir de puente entre la comunidad y el Estado",
                "Proponer, analizar y votar proyectos de acuerdo",
                "Todas las anteriores",
                "Otra"
              ], valor:"" },
              { "no": "15", "nombre": "¿Ha asistido a alguna sesión del Concejo Municipal?", "tipo": "Múltiple", "opciones": [ "Sí", "No"], valor:"" },
              { "no": "16", "nombre": "¿El Concejo Municipal le genera confianza?", "tipo": "Múltiple", "opciones": [ "Sí", "No"], valor:"" },
              { "no": "17", "nombre": "Desde su percepción, ¿Cómo califica la gestión del Concejo Municipal?", "tipo": "Múltiple", "opciones": [ "Muy buena", "Buena", "Regular", "Mala", "Muy mala"], valor:"" },
              { "no": "18", "nombre": "¿Cómo califica la relación del Concejo Municipal con la ciudadanía?", "tipo": "Múltiple", "opciones": [ "Cercana", "Distante", "Ausente"], valor:"" },
              { "no": "19", "nombre": "¿El Concejo Municipal le da aconocer sus propuestas o ideas?", "tipo": "Múltiple", "opciones": [ "Sí", "No"], valor:"" },
              { "no": "20", "nombre": "¿Cómo califica la información que da a conocer el Concejo Municipal?", "tipo": "Múltiple", "opciones": [ "Muy buena", "Buena", "Regular", "Mala", "Muy mala"], valor:"" },
              { "no": "21", "nombre": "¿A través de qué medios de comunicación se entera usted de lo que pasa con el Concejo Municipal?", "tipo": "Múltiple", "opciones": [
                 "Redes sociales", "De la voz de otras personas", "Medios impresos", "Otro"], valor:"" },
              { "no": "22", "nombre": "¿Cómo califica los medios de comunicación que utiliza el Concejo Municipal?", "tipo": "Múltiple", "opciones": [ 
                "Muy buenos", "Buenos", "Regulares", "Malos", "Muy malos"], valor:"" },
              { "no": "23", "nombre": "¿Conoce a los concejales de su municipio?", "tipo": "Múltiple", "opciones": [ 
                "Sí los conozco a todos", 
                "Conozco a algunos de ellos",
                "No conozco a ninguno de ellos",
                "No me interesa"
              ], valor:"" },
              { "no": "24", "nombre": "¿Conoce los partidos políticos a los que pertenecen los candidatos de su municipio?", "tipo": "Múltiple", "opciones": [ "Sí", "No"], valor:"" },
              { "no": "26", "nombre": "¿Ha tenido cercanía con los concejales de su municipio?", "tipo": "Múltiple", "opciones": [ 
                "Alguno de ellos es mi amigo", 
                "Alguno de ellos se ha acercado a mi o a mi comunidad",
                "Los conozco pero no tengo cercanía con ninguno de ellos",
                "No me interesa",
              ], valor:"" },
            ] 
          }

        }
      },
      methods: {
        iniciarEncuesta(){
          this.estadoEncuesta = 'En curso';
          ////acceso al microfono
          //acceso al gps
        },
        enviarEncuestaFinal(event) {
          event.preventDefault()
          alert(JSON.stringify(this.formulario));
          /*COLCOAR PARTE PARA MANDAR AL SERVIDOR*/
          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });


          
        },
      }
    }
  </script>